import React from 'react';


function NotFound() {
  return (
    <div>
      <p>Not Found</p>
    </div>
  );
}

export default NotFound;
